import 'core-js/stable'
import Vue from 'vue'
import App from './App'
import router from './router'
import CoreuiVue from '@coreui/vue'
import { iconsSet as icons } from './assets/icons/icons.js'
import store from './store/store'

Vue.config.performance = true
Vue.use(CoreuiVue)
Vue.prototype.$log = console.log.bind(console);

/* vee-validate library */
import { ValidationObserver, ValidationProvider, configure } from 'vee-validate/dist/vee-validate.full';
Vue.component('ValidationProvider', ValidationProvider);
Vue.component('ValidationObserver', ValidationObserver);

/* Vue input mask */
import VueMask from 'v-mask'
Vue.use(VueMask);

import Snotify from 'vue-snotify';
import "vue-snotify/styles/material.css";
Vue.use(Snotify);

//global registration
import VueFormWizard from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
Vue.use(VueFormWizard);

/* Vue Overlay library for loading spiner */
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.component('loading', Loading);

import OtpInput from "@bachdgvn/vue-otp-input";
Vue.component("v-otp-input", OtpInput);

var VueScrollTo = require('vue-scrollto');
Vue.use(VueScrollTo);

import vSelect from 'vue-select'
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

import ReadMore from 'vue-read-more';
Vue.use(ReadMore);

import StarRating from 'vue-star-rating'
Vue.use(StarRating);

import Vue2Filters from 'vue2-filters'
Vue.use(Vue2Filters)

import * as VueGoogleMaps from "vue2-google-maps";
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyC2IRiWVY6Fqx7NVjOQAeQhy4ONM5MwWUM",
  },
  installComponents: true,
});


Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('keyup', (e1) => {
      e1.target.value = e1.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e1.target.value.toUpperCase())
    })
  }
})

Vue.directive('lowercase', {
  bind(el, _, vnode) {
    el.addEventListener('keyup', (e1) => {
      e1.target.value = e1.target.value.toLowerCase()
      vnode.componentInstance.$emit('input', e1.target.value.toLowerCase())
    })
  }
});

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  },
  beforeCreate() {
    Vue.$snotify = this.$snotify;
  }
})
