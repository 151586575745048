import router from "@/router";
import { http } from "@/config/http";
import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
    return {
        errors: []
    };
};

const state = {

};

const getters = {

};

const mutations = {
    setFormErrors(state, data) {
        state.errors = [];
        if (data) {
            state.errors = data.details;
        }
    },
};

const actions = {

    async getRestaurantData({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .get("restaurant/guest/show/" + data.tenant_id + "", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async getMenuCategoryArray({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .get("menu_category/guest/tree/" + data.tenant_id, data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //signup new restaurant
    async signupRestaurantRequest({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("restaurant/signup/create", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        )
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async getAllRestaurants({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("restaurant/guest/all?page=" + data.page + "", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        // commit(
                        //     "showAlert",
                        //     {
                        //         title: "Success!",
                        //         msg: response.data.success,
                        //         type: "success",
                        //         position: "centerTop"
                        //     },
                        //     { root: true }
                        // )
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //filter menu items
    async filterMenuItems({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("restaurant/guest/filter_menu_items/" + data.tenant_id + "/" + data.meal_type + "", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //Order confirmation
    async orderConfirm({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/order_confirmation", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        )
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //reservation booking
    async reservationBooking({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/reservation_create", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        )
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //reservation booking
    async orderCloseByCustomer({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/order_close_by_customer", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                     
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },


};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
