import router from "@/router";
import {http} from "@/config/http";
import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
    return {
        errors: []
    };
};

const state = {

};

const getters = {

};

const mutations = {
    setFormErrors(state, data) {
        state.errors = [];
        if (data) {
            state.errors = data.details;
        }
    },
};

const actions = {
    async cityList({commit}, data) {
        return new Promise((resolve, reject) => {
            http
                .post("settings/city_list", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            {root: true}
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },
    async mealList({commit}, data) {
        return new Promise((resolve, reject) => {
            http
                .post("settings/meal_list", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            {root: true}
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },
    async menuTypeList({commit}, data) {
        return new Promise((resolve, reject) => {
            http
                .post("settings/menu_type_list", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            {root: true}
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },
    async cuisineList({commit}, data) {
        return new Promise((resolve, reject) => {
            http
                .post("settings/cuisine_list", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            {root: true}
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async serviceStationTypeList({commit}, data) {
        return new Promise((resolve, reject) => {
            http
                .post("settings/service_location_type_list", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            {root: true}
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async menuCategotyList({commit}, data) {
        return new Promise((resolve, reject) => {
            http
                .post("settings/menu_categoty_list", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            {root: true}
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async areaList({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/dropdown_list", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
