<template>
  <router-view></router-view>
</template>

<script>
import { mapActions, mapState } from "vuex";
import store from "./store/store";
export default {
  name: "App",
  computed: {
    ...mapState("user", ["user_id", "active_reservation_id"]),
    orderRatingModal: {
      get() {
        return this.$store.state.orderRatingModal;
      },
      set(value) {
        this.$store.state.orderRatingModal = value;
      },
    },
  },
  methods: {
    ...mapActions("user", ["refreshToken"]),
    ...mapActions(["getMyOrderCart"]),
  },
  async mounted() {
    var that = this;
    if (that.$snotify.notifications.length > 0) {
      that.$snotify.notifications.forEach((notification) => {
        that.$snotify.remove(notification.id);
      });
    }
    
  },
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";
</style>
