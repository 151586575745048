import Vue from 'vue'
import Router from 'vue-router'

// Containers
const TheContainer = () =>
    import ('@/containers/TheContainer')

const LandingPageContainer = () =>
    import ('@/containers/LandingPageContainer')

const UnregisterContainer = () =>
    import ('@/containers/UnregisterContainer')

// Views
const Dashboard = () =>
    import ('@/views/Dashboard')

// Views - Pages
const Page404 = () =>
    import ('@/views/pages/Page404')

const RestaurantSearch = () =>
    import ('@/views/pages/RestaurantSearch/RestaurantSearch')

const RestaurantRegistration = () =>
    import ('@/views/pages/RestaurantRegistration/RestaurantRegistration')

const CustomerProfile = () =>
    import ('@/views/pages/CustomerProfile/CustomerProfile')

const RestaurantMenuView = () =>
    import ('@/views/pages/RestaurantMenuView/RestaurantMenuViewNew')
const RestaurantMenuViewNew = () =>
    import ('@/views/pages/RestaurantMenuView/RestaurantMenuViewNew.vue')
const Login = () =>
    import ('@/views/pages/Login')

const Register = () =>
    import ('@/views/pages/Register')

const RestaurantBookingForm = () =>
    import ('@/component/RestaurantCard/ResturantBookingForm')

const RestaurantMenuCard = () =>
    import ('@/component/RestaurantMenuCard/RestaurantMenuCard')

const RestaurantMapView = () =>
    import ('@/views/pages/RestaurantMapViewGlobal')

const MyOrders = () =>
    import ('@/views/pages/MyOrders')

const AboutUs = () =>
    import ('@/views/pages/AboutUs/AboutUs')

const MyReservations = () =>
    import ('@/views/pages/MyReservations')
const RestaurantMenuViewDev = () =>
    import ('@/views/pages/RestaurantMenuView/RestaurantMenuViewDev.vue')

const ContactUs = () =>
    import ('@/views/pages/ContactUs/ContactUs')


Vue.use(Router)

export default new Router({
    mode: 'hash', // https://router.vuejs.org/api/#mode
    linkActiveClass: 'active',
    scrollBehavior: () => ({ y: 0 }),
    routes: configRoutes()
})

function configRoutes() {
    return [{
            path: '/',
            redirect: '/',
            name: 'Home',
            component: LandingPageContainer,
            children: [{
                path: '',
                name: 'Dashboard',
                component: Dashboard
            }, {
                path: '/restaurant-signup',
                name: 'RestaurantRegistration',
                component: RestaurantRegistration
            }, ]
        },

        {
            path: '/',
            redirect: '/',
            name: 'Home',
            component: UnregisterContainer,
            children: [{
                    path: 'restaurant_booking/:tenant_id',
                    name: 'RestaurantBookingForm',
                    component: RestaurantBookingForm
                },
                {
                    path: 'restaurant-menu-view/:tenant_id',
                    name: 'RestaurantMenuViewNew',
                    component: RestaurantMenuViewNew
                },
                {
                    path: 'restaurant-menu-view-new/:tenant_id',
                    name: 'RestaurantMenuViewNew',
                    component: RestaurantMenuViewNew
                },
                {
                    path: 'aaa/:tenant_id',
                    name: 'RestaurantMenuViewDev',
                    component: RestaurantMenuViewDev
                },
                {
                    path: 'RestaurantMenuCard',
                    name: 'RestaurantMenuCard',
                    component: RestaurantMenuCard
                },
                {
                    path: 'my-orders',
                    name: 'MyOrders',
                    component: MyOrders
                },
                {
                    path: '/my-profile',
                    name: 'CustomerProfile',
                    component: CustomerProfile
                },
                {
                    path: 'my-reservations',
                    name: 'MyReservations',
                    component: MyReservations
                },
            ]
        },
        {
            path: '/pages',
            redirect: '/pages/404',
            name: 'Pages',
            component: {
                render(c) { return c('router-view') }
            },
            children: [{
                    path: '404',
                    name: 'Page404',
                    component: Page404
                },
                {
                    path: 'map',
                    name: 'map',
                    component: RestaurantMapView
                },
                {
                    path: 'RestaurantSearch',
                    name: 'RestaurantSearch',
                    component: RestaurantSearch
                },
                {
                    path: '/login',
                    name: 'Login',
                    component: Login
                },
                {
                    path: '/register',
                    name: 'Register',
                    component: Register
                },
                {
                    path: 'aboutus',
                    name: 'AboutUs',
                    component: AboutUs
                },
                {
                    path: 'contactus',
                    name: 'ContactUs',
                    component: ContactUs
                },
            ]
        }
    ]
}
