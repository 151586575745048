import router from "@/router";
import { http } from "@/config/http";
import _ from "lodash";

const namespaced = true;

const getDefaultState = () => {
    return {
        errors: [],
        customer_access_token: null,
        user_name: null,
        user_id: null,
        active_reservation_id: null,
    
    };
};

const state = {
    customer_access_token: localStorage.getItem("customer_access_token") || null,
    user_name: localStorage.getItem("user_name") || null,
    user_id: localStorage.getItem("user_id") || null,
    active_reservation_id: localStorage.getItem("active_reservation_id") || null
};

const getters = {
    loggedIn() {
        return localStorage.getItem("customer_access_token") != null;
    },
};

const mutations = {
    setFormErrors(state, data) {
        state.errors = [];
        if (data) {
            state.errors = data.details;
        }
    },
    doLogin(state, response) {
        let contentData = response.data;
        const token = contentData.customer_access_token;
        if (token) {
            localStorage.setItem("customer_access_token", token);
            localStorage.setItem("user_name", contentData.user_data.first_name + " " + (contentData.user_data.last_name ? contentData.user_data.last_name : ""));
            localStorage.setItem("user_id", contentData.user_data.user_id);
            state.user_id = contentData.user_data.user_id;
            state.customer_access_token = token;
            state.user_name = contentData.user_data.first_name + " " + (contentData.user_data.last_name ? contentData.user_data.last_name : "");
            router.push("/");
        } else {

        }
    },

    refreshToken(state, response) {
        let contentData = response.data;
        const token = contentData.customer_access_token;
        if (token) {
            localStorage.setItem("customer_access_token", token);
            localStorage.setItem("user_name", contentData.user_data.first_name + " " + (contentData.user_data.last_name ? contentData.user_data.last_name : ""));
            localStorage.setItem("user_id", contentData.user_data.user_id);
            state.user_id = contentData.user_data.user_id;
            state.customer_access_token = token;
            state.user_name = contentData.user_data.first_name + " " + (contentData.user_data.last_name ? contentData.user_data.last_name : "");
        } else {

        }
    },

    setActiveReservationData(state, response) {
        let contentData = response.data;
        const activeReservationId = contentData.active_reservation_id;
        if (activeReservationId) {
            localStorage.setItem("active_reservation_id", activeReservationId);
            state.active_reservation_id = activeReservationId;
        }
    },

    clearActiveReservationData(state, response) {
        localStorage.removeItem("active_reservation_id");
        state.active_reservation_id = null;
    },


    loginRefreshToken(state, response) {
        let contentData = response.data;
        const token = contentData.customer_access_token;
        if (token) {
            localStorage.setItem("customer_access_token", token);
            localStorage.setItem("user_name", contentData.user_data.first_name + " " + contentData.user_data.last_name);
            localStorage.setItem("user_id", contentData.user_data.user_id);
            state.user_id = contentData.user_data.user_id;
            state.customer_access_token = token;
            state.user_name = contentData.user_data.first_name + " " + contentData.user_data.last_name;
            router.push("/");
        } else {

        }
    },
    refreshCustomerToken(state, response) {
        let contentData = response.data.data;
        const token = contentData.customer_access_token;
        if (token) {
            localStorage.setItem("customer_access_token", token);
            localStorage.setItem("user_name", contentData.user_data.first_name + " " + contentData.user_data.last_name);
            localStorage.setItem("user_id", contentData.user_data.user_id);
            state.user_id = contentData.user_data.user_id;
            state.customer_access_token = token;
            state.user_name = contentData.user_data.first_name + " " + contentData.user_data.last_name;
        } else {

        }
    },


    doLogout(state) {
        localStorage.removeItem("customer_access_token");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        localStorage.removeItem("active_reservation_id");
        state.customer_access_token = null;
        state.user_name = null;
        state.user_id = null;
        state.active_reservation_id = null;
        router.push({ name: 'Dashboard'});
    },

    clearLogin(state) {
        localStorage.removeItem("customer_access_token");
        localStorage.removeItem("user_name");
        localStorage.removeItem("user_id");
        localStorage.removeItem("active_reservation_id");
        state.customer_access_token = null;
        state.user_name = null;
        state.user_id = null;
        state.active_reservation_id = null;
    },  
};

const actions = {
    async getCurrentLoggedCustomer({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/get_current_user", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async updateCurrentLoggedCustomer({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/update_current_user", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async genereateCustomerOtpForReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/otp/create_reservation", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async genereateCustomerRegisterOtp({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/generate_customer_register_otp", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },



    async checkCustomerMobileForReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_customer_mobile_reservation", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async genereateCustomerOtp({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/otp/create", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async resendOtpRequest({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/otp/resend", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },
    

    async checkCustomerMobile({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_customer_mobile", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async checkOtpValidity({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/otp/check_validity", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async checkCustomerInfoValidity({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_customer_info_validity", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        if (data.refresh_required) {
                            commit("loginRefreshToken", response);
                        } else {
                            commit("refreshToken", response);
                        }

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async generateCustomerToken({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/generate_token", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        if (data.refresh_required) {
                            commit("doLogin", response);
                        } else {
                            commit("refreshToken", response);
                        }

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async generateCustomerTokenAfterReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/generate_after_reservation_token", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit("refreshCustomerToken", response);
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async destroyToken({ commit }) {
        await http
            .post("customer/logout", null, {})
            .then(response => {
                if (response.data.status == "true") {
                    commit("doLogout");
                } else {
                    commit("doLogout");
                }
            })
            .catch(error => {
                commit("handleExceptions", error, { root: true });
                commit("doLogout");
            });
    },

    //check reservation number validity
    async checkReservationNumberValidity({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_reservation_number_validity", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit("refreshToken", response.data);
                        commit("setActiveReservationData", response.data);
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //check reservation number validity
    async makeInstantReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/make_instant_reservation", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit("refreshToken", response.data);
                        commit("setActiveReservationData", response.data);
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //check reservation number validity
    async makeInstantRegisteredReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/make_instant_registered_reservation", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit("setActiveReservationData", response.data);
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //Check Customer Access Token Validity
    async checkCustomerAccessTokenValidity({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_customer_access_token_validity", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        //commit("refreshToken", response);
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //Refresh customer token
    async refreshToken({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/refresh_token", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit("refreshToken", response);
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async genereateCustomerCommonOtp({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/generate_customer_common_otp", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async genereateCustomerValidateOtp({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/generate_customer_validate_otp", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async checkTableAvailability({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_table_availability", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },
    
};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
