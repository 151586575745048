import router from "@/router";
import { http } from "@/config/http";
import _ from "lodash";
import { throttle } from "lodash";

const namespaced = true;

const getDefaultState = () => {
    return {
        errors: [],
        orderItems: [],
        SOSDisable: false
    };
};

const state = getDefaultState();

const getters = {

};

const mutations = {

    setOrderDataData(state, data) {
        state.orderItems = data;
    },

    setFormErrors(state, data) {
        state.errors = [];
        if (data) {
            state.errors = data.details;
        }
    },

    resetOrderDataData(state, data) {
        state.OrderData.orderItems = [];
    },
    enableSOSButton(state) {
        state.SOSDisable = false;
    },
    disableSOSButton(state) {
        state.SOSDisable = true;
    }
};

const actions = {
    //Get Order Data
    async getOrderItemsData({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/get_order_items/" + data.id + "", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit("setOrderDataData", response.data);
                    }
                    // resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async confirmCustomerOrderItem({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/order_item_confirmation", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                        resolve(response);
                    }
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },


    //create call waiter sos request
    async createCallWaiterRequest({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/create_table_waiter_request", data)

                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {

                        commit('disableSOSButton');
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        )
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },


    async printReceipt({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/print_order_bill", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async createRestaurantRating({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/create_restaurant_rating", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }else {
                        commit(
                            "showAlert",
                            {
                                title: "Success!",
                                msg: response.data.success,
                                type: "success",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    //check for pending sos calls
    checkPendingSOSStatus: throttle(async function ({ commit, state }, data) {
        // async checkPendingSOSStatus({ commit }, data) {
        console.log('ttt');
        return new Promise((resolve, reject) => {
            http
                .post("customer/pending_sos_request_status", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        if (response.data.data.pending_sos_request_exist == true) {
                            commit('disableSOSButton');
                        } else {
                            commit('enableSOSButton');
                        }

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    }, process.env.VUE_APP_TIMEOUT_SOS_DATA),


    async setSOSStatus({ commit }, data) {

        return new Promise((resolve, reject) => {
            http
                .post("customer/pending_sos_request_status", data)
                .then(response => {
                    if (response.data.error) {
                        commit(
                            "showAlert",
                            {
                                title: "Error!",
                                msg: response.data.error,
                                type: "error",
                                position: "centerTop"
                            },
                            { root: true }
                        );
                    } else {
                        if (response.data.data.pending_sos_request_exist == true) {
                            commit('disableSOSButton');
                        } else {
                            commit('enableSOSButton');
                        }

                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });

    },

    clearData({ commit }, data) {
        commit("resetOrderDataData");
    },

    async checkActiveReservation({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_active_reservation", data)
                .then(response => {
                    if (response.data.error) {
                    }else {
                       
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

    async checkReservationHost({ commit }, data) {
        return new Promise((resolve, reject) => {
            http
                .post("customer/check_reservation_host", data)
                .then(response => {
                    if (response.data.error) {
                    }else {
                       
                    }
                    resolve(response);
                })
                .catch(error => {
                    commit("handleExceptions", error, { root: true });
                    resolve(error.response);
                });
        });
    },

};

export default {
    namespaced,
    state,
    getters,
    actions,
    mutations
};
