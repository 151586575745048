import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex);
import restaurant from "@/store/modules/restaurant";
import settings from "@/store/modules/settings";
import user from "@/store/modules/user";
import order from "@/store/modules/order";
import { http } from "@/config/http";
import { SnotifyPosition, SnotifyStyle } from 'vue-snotify';
const namespaced = true;
const state = {
  sidebarShow: false,
  sidebarMinimize: false,
  activeMealTypeBtn: "BF",
  spinerColor: "#653DCC",
  spinerLoader: "bars",
  activeMenuCategoryId: "",
  allRestaurantsNameArray: [],
  allRestaurants: [],
  menuItemCart: {
    order_items: [],
    tenant_id: "",
    order_total: 0,
    order_discount: 0
  },
  service_charge: 0,
  orderRatingModal:false
}

const mutations = {
  toggleSidebarDesktop(state) {
    const sidebarOpened = [true, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarOpened ? false : 'responsive'
  },
  toggleSidebarMobile(state) {
    const sidebarClosed = [false, 'responsive'].includes(state.sidebarShow)
    state.sidebarShow = sidebarClosed ? true : 'responsive'
  },
  set(state, [variable, value]) {
    state[variable] = value
  },
  handleExceptions(state, error) {
    if (error.response) {
      if (error.response.data.error) {
        if (error.response.data.code != 422) {
          this.commit(
            "showAlert",
            {
              title: "Error!",
              msg: error.response.data.error,
              type: "error",
              position: "centerTop"
            },
            { root: true }
          );
        }
      }
      else {
        this.commit(
          "showAlert",
          {
            title: "Error!",
            msg: "Oops..something went wrong.",
            type: "error",
            position: "centerTop"
          },
          { root: true }
        );
      }
    } else {
      this.commit(
        "showAlert",
        {
          title: "Error!",
          msg: "Oops..something went wrong.Please check your internet connectivity",
          type: "error",
          position: "centerTop"
        },
        { root: true }
      );
    }

  },
  showAlert: (state, data) => {
    let type = '';
    let position = '';
    switch (data.type) {
      case "error": {
        type = SnotifyStyle.error;
        break;
      }
      case "success": {
        type = SnotifyStyle.success;
        break;
      }
      case "info": {
        type = SnotifyStyle.info;
        break;
      }
      case "warning": {
        type = SnotifyStyle.warning;
        break;
      }
      case "async": {
        type = SnotifyStyle.async;
        break;
      }
      case "confirm": {
        type = SnotifyStyle.confirm;
        break;
      }
      case "prompt": {
        type = SnotifyStyle.prompt;
        break;
      }

      default: {
        type = SnotifyStyle.simple;
        break;
      }
    }
    switch (data.position) {
      case "leftTop": {
        position = SnotifyPosition.leftTop;
        break;
      }
      case "leftCenter": {
        position = SnotifyPosition.leftCenter;
        break;
      }
      case "leftBottom": {
        position = SnotifyPosition.leftBottom;
        break;
      }
      case "rightTop": {
        position = SnotifyPosition.rightTop;
        break;
      }
      case "rightCenter": {
        position = SnotifyPosition.rightCenter;
        break;
      }
      case "rightBottom": {
        position = SnotifyPosition.rightBottom;
        break;
      }
      case "centerCenter": {
        position = SnotifyPosition.centerCenter;
        break;
      }
      case "centerTop": {
        position = SnotifyPosition.centerTop;
        break;
      }
      case "centerBottom": {
        position = SnotifyPosition.centerBottom;
        break;
      }

      default: {
        position = SnotifyPosition.rightTop;
        break;
      }
    }
    Vue.$snotify.clear();

    Vue.$snotify.create({
      title: data.title,
      body: data.msg,
      config: {
        position: position,
        type: type,
        timeout: 2000,
        closeOnClick: true,
        pauseOnHover: true,
      }
    })
  },
  setMenuCart(state, response) {
    localStorage.setItem("menuItemCart", JSON.stringify(response));
  },
  clearMenuCart(state, response) {
    localStorage.setItem("menuItemCart", null);
    state.menuItemCart = {
      order_items: [],
      tenant_id: "",
      order_total: 0,
      order_discount: 0
    };
  },
  setMyOrderCart(state, response){
    state.menuItemCart = response;
  }
}

const actions = {
  async getMyOrderCart({ commit }, data) {
    return new Promise((resolve, reject) => {
      http
        .post("customer/get_my_order_cart", data)
        .then(response => {
          if (response.data.error) {
            commit(
              "showAlert",
              {
                title: "Error!",
                msg: response.data.error,
                type: "error",
                position: "centerTop"
              },
              { root: true }
            );
          } else {
            commit("setMyOrderCart",response.data);
          }
        })
        .catch(error => {
          commit("handleExceptions", error, { root: true });
          resolve(error.response);
        });
    });
  },
}

export default new Vuex.Store({
  namespaced,
  state,
  mutations,
  actions,
  modules: {
    restaurant,
    settings,
    user,
    order
  },
})